import React, {useEffect} from 'react'
import styled from "styled-components";

import {WhatsAppButton} from "../icons/socialMedia/whats-app-button.jsx";
import {NutritionImage} from "../icons/illustartions/nutrition/nutrition-image.jsx";
import {FacebookButton} from "../icons/socialMedia/facebook-button.jsx";

export const Nutrition = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <div>
        ** <MainLabel>נפגשתי עם נטע מלחי, שהיא דיאטנית מומחית לפוריות -</MainLabel> קראתי הרבה על החשיבות שיש לתזונה שלנו על תפקוד הגוף, אז החלטתי לפנות לאיש מקצוע וככה הגעתי לנטע, שהיא מבקשת בדיקות דם, עוברת עליהן ועושה סדר באוכל ובשעות לקיחת תוספי התזונה. לפי מה שנטע המליצה לי, שיניתי את התזונה שלי - בעיקר הרבה קטניות ומעט סוכר. פירוט למטה.<br/>
        <span>לעמוד הפייסבוק של נטע - </span> <FacebookLink href="https://www.facebook.com/people/%D7%A0%D7%98%D7%A2-%D7%9E%D7%9C%D7%97%D7%99-%D7%93%D7%99%D7%90%D7%98%D7%A0%D7%99%D7%AA-%D7%A4%D7%95%D7%A8%D7%99%D7%95%D7%AA/100063571692822/?locale=he_IL"><FacebookButton width="30px" height="30px"/></FacebookLink><br/>
        <span>להתייעצות עם נטע בוואטסאפ - </span><a href={"https://wa.me/972545224754?text=היי%20נטע,%20קראתי%20את%20האתר%20ביציות%20שהדס%20ושחר%20יצרו%20ואשמח%20להתייעץ%20איתך%20בנושא%20זה."} ><WhatsAppButton width="25px" height="30px"/></a><br/>

        <br/>
        <StyledSubHeader>הפחתתי:</StyledSubHeader>
        <ul>
          <li>שומן טראנס, שנמצא במזונות מתועשים כגון: עוגות, עוגיות, בורקסים, ג'חנון, רוגעלך וחטיפים.</li>
          <li>פחמימות בעלי מדד גליקמי גבוה, גורמות לירידה ברגישות לאינסולין ומעלות את הסיכון לאי פיריון אז להפחית: סוכר, ממתקים, שתייה מתוקה, סוכריות, קמח לבן, דגני בוקר ותפוח אדמה.</li>
          <li>בשר מעובד - להמנע, לדוגמא: נקניקיות, נקניקים, קבב והמבורגר</li>
          <li>פחות בשר שמן.</li>
        </ul>

        <StyledSubHeader>הוספתי:</StyledSubHeader>
        <ul>
          <li>חומצות שומן בלתי רוויות ובייחוד חד-בלתי רוויות ובהן שמן זית, שמן קנולה, אבוקדו, טחינה, שקדים, בוטנים ואגוזי לוז.</li>
          <li>אגוזים ושקדים - לשלב כמה שיותר כי הם עשירים במינרלים ונוגדי חמצון (מי שצריכה לרדת במשקל אז ההמלצה היא עד 2 חופנים ביום)</li>
          <li>לחם מלא עם גרעינים שלמים.</li>
          <li>דייסת שיבולת שועל.</li>
          <li>קטניות: חומוס, פולי סויה, עדשים, שעועית ואפונה.</li>
          <li>דגים - 2 עד 3 מנות דג בשבוע.</li>
          <li>ירקות ופירות - לשלב כמה שיותר ירקות בכל אופני ההכנה (טריים, אפויים, מבושלים...) ובכמה שיותר צבעים וכמה שיותר פירות טריים.</li>
          <li>דגנים מלאים - אורז, חיטה, בטטה, בורגול, כוסמת, גריסים.</li>
          <li>סויה וטופו - מוצרים שהם לא מעובדים כמו מעדנים על בסיס סויה.</li>
        </ul>

        <StyledSubHeader>דגשים:</StyledSubHeader>
        <ul>
          <li><MainLabel>רמות אינסולין מאוזנות -</MainLabel> דאגתי שלא יהיו קפיצות סוכר על ידי זה שאכלתי משהו כל שעתיים שלוש לפי מה שנטע הגדירה, ומתוקים (כולל פירות) רצוי לאכול עם אגוזים ושקדים.</li>
          <li>אוכל כמה שיותר קרוב למקור, כמה שפחות תהליכי עיבוד וכמה שפחות תוספים - יותר טוב.</li>
          <li>לשים לב שחומרי הדברה מורידים את סיכויי הפוריות ולכן רצוי לשטוף ולנגב היטב פירות וירקות.</li>
          <li>הפחתת זליגה של פלסטיקים לתוך אוכל ושתייה - חימום במיקרו רק בכלי שמיועד לכך + להפחית אכילה ושתייה בכלים חד פעמיים + הפחתת שימוש בקופסאות שימורים + שתיית מים בבקבוק ללא ביספינול A.</li>
          <li>העדפת בישול ואפייה על פני צלייה.</li>
        </ul>
      </div>
      <ImageWrapper><StyledNutritionImage /></ImageWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 500px - 171px);
`

const StyledSubHeader = styled.h3`
  font-size: 20px;
`

const MainLabel = styled.span`
  font-weight: bold;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: end;
  mix-blend-mode: multiply;
`

const StyledNutritionImage = styled(NutritionImage)`
  width: 260px;
  height: 290px;

  @media (min-width: 1025px) {
    width: 300px;
    height: 370px;
  }
`

const FacebookLink = styled.a`
  margin-inline-end: 10px;
`