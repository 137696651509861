import React, {useEffect} from 'react'
import styled from "styled-components";

import {SupplementImage} from "../icons/illustartions/supplement/supplement-image.jsx";

export const Supplement = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <div>
        <p>** שימו לב שתוספי תזונה יש לקחת רק בהתייעצות רפואית עם רופא או דיאטנית מומחית כי זה משתנה מגוף לגוף ומרגישות כזאת או אחרת.</p>
        <ul>
          <li>פרוביוטיקה ג'ארו</li>
          <li>מולטי ויטמין</li>
          <li>חומצה פולית</li>
          <li>ויטמין די</li>
          <li>אומגה 3</li>
          <li>ברזל</li>
          <li>Q10 - לקחתי 600 מ״ג ביום אז קניתי 3 קופסאות של 200 מ״ג</li>
          <li>DHEA - לקחתי 75 מ״ג ביום אז הזמנתי אחד 50 מ״ג ואת ה-25 מ״ג</li>
        </ul>
      </div>
      <ImageWrapper><StyledSupplementImage/></ImageWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 500px - 171px);
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: end;
  mix-blend-mode: multiply;
  margin-inline-start: 44px;
`

const StyledSupplementImage = styled(SupplementImage)`
  width: 305px;
  height: 341px;

  @media (min-width: 600px) and (max-width: 800px) {
    width: 232px;
    height: 225px;
  }
  
  @media (min-width: 800px) and (max-width: 1024px){
    width: 406px;
    height: 330px;
  }

  @media (min-width: 1025px) {
    width: 433px;
    height: 423px;
  }
`