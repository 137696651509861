import React, {useEffect} from 'react'
import styled from "styled-components";
import {LifeStyleImage} from "../icons/illustartions/lifestyle/lifestyle-image.jsx";

export const LifeStyle = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Container>
      <div>
        <ul>
          <li><MainLabel>מים -</MainLabel> לפחות 2 ליטר ביום.</li>
          <li><MainLabel>שינה -</MainLabel> ישנתי לפחות 7 שעות.</li>
          <li><MainLabel>אלכוהול -</MainLabel> עד 3 כוסות בשבוע.</li>
          <li><MainLabel>ספורט -</MainLabel> התאמנתי יותר משעה ופחות מ-5 שעות בשבוע (גם הליכה זה אימון).</li>
          <li><MainLabel>נפש רגועה -</MainLabel> עשיתי יוגה לפחות פעם בשבוע ומדיטציה כמעט כל יום בבוקר.</li>
          <li><MainLabel>קפאין -</MainLabel> עד 3 כוסות קפה ביום - לשים לב שגם בתה, קולה ומשקאות אנרגיה למיניהם יש קפאין - אז לבחור את אלו שאין להם.</li>
          <li><MainLabel>משקל תקין -</MainLabel> דאגתי לא להיות בתת משקל כלומר מעל BMI 20 כי הורמונים החיונים לפוריות מופרשים מרקמות השומן ולא להיות בעודף משקל (מעל BMI 25).</li>
          <li><MainLabel>דיקור -</MainLabel> הלכתי לדיקור פעם בשבוע דרך כללית רפואה משלימה אצל מדקר שהמומחיות שלו היא פוריות. בחצי שעה שבה היו לי את המחטים בגוף דאגתי לעשות דמיון מודרך שבו אני מדמיינת איך הטיפול הזה גורם לביציות שלי להיות איכותיות והכמות גדלה.</li>
          <li><MainLabel>גלולות -</MainLabel> הפסקתי לקחת.</li>
          <li>להמנע מעישון.</li>
        </ul>
      </div>
      <ImageWrapper><StyleLifeStyleImage/></ImageWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 500px - 171px);
`

const MainLabel = styled.span`
  font-weight: bold;
`

const ImageWrapper = styled.div`
  display: flex;
  justify-content: end;
  mix-blend-mode: multiply;
`

const StyleLifeStyleImage = styled(LifeStyleImage)`
  width: 260px;
  height: 290px;

  @media (min-width: 600px) and (max-width: 800px) {
    width: 223px;
    height: 233px;
  }
  
  @media (min-width: 800px) and (max-width: 1024px){
    width: 530px;
    height: 430px;
  }

  @media (min-width: 1025px) {
    width: 433px;
    height: 423px;
  }
`