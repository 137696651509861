import React, {useEffect, useState} from 'react'
import styled from "styled-components";

import {MainPageHeader} from "../App.js";

import {Nutrition} from "./Nutrition.jsx";
import {Supplement} from "./Supplement.jsx";
import {LifeStyle} from "./LifeStyle.jsx";
import {NutritionLogo} from "../icons/illustartions/nutrition/nutrition-logo.jsx";
import {LifeStyleLogo} from "../icons/illustartions/lifestyle/lifestyle-logo.jsx";
import {SupplementLogo} from "../icons/illustartions/supplement/supplement-logo.jsx";

export const EggsFactory = () => {
  const [selectedTab, setSelectedTab] = useState("nutrition");

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <StyledEggsFactory>
      <MainPageHeader>מפעל ביציות</MainPageHeader>
      <StyledSubHeader>אז מה עשיתי כדי לשפר את איכות הביציות?</StyledSubHeader>
      <Tabs>
        <Tab onClick={() => setSelectedTab("nutrition")} className={selectedTab === "nutrition" ? "active" : ""}>
          <IconWrapper>
            <NutritionLogo fill={selectedTab === "nutrition" ? "#F36940" : "#425F7E"} />
          </IconWrapper>תזונה</Tab>
        <Tab onClick={() => setSelectedTab("supplement")} className={selectedTab === "supplement" ? "active" : ""}>
          <IconWrapper>
            <SupplementLogo fill={selectedTab === "supplement" ? "#F36940" : "#425F7E"} />
          </IconWrapper>תוספי תזונה</Tab>
        <Tab onClick={() => setSelectedTab("lifestyle")} className={selectedTab === "lifestyle" ? "active" : ""}>
          <IconWrapper>
            <LifeStyleLogo fill={selectedTab === "lifestyle" ? "#F36940" : "#425F7E"} />
          </IconWrapper>אורח חיים</Tab>
      </Tabs>
      <Line/>
      <div>
        {selectedTab === "nutrition" && <Nutrition/>}
        {selectedTab === "supplement" && <Supplement/>}
        {selectedTab === "lifestyle" && <LifeStyle/>}
      </div>
    </StyledEggsFactory>
  )
}

const StyledEggsFactory = styled.div`
  position: relative;
  background: #F5F5F5;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 34px 16px 0;

  @media (min-width: 600px) {
    padding: 36px 28px 0;
  }

  @media (min-width: 1025px) {
    padding: 52px 100px 0;
    font-size: 18px;
    line-height: 28px;
  }
`

const StyledSubHeader = styled.h3`
  font-size: 20px;
  line-height: 29.3px;
  margin-block-end: 12px;
  
  @media (min-width: 1025px) {
    font-size: 28px;
  }
`

const Tabs = styled.div`
  display: flex;
  position: sticky;
  justify-content: flex-start;
  padding: 28px 0 12px 0;
  background: inherit;
  width: 100%;
  z-index: 3;
  top: 88px;

  @media (min-width: 600px) {
    top: 104px;
  }

  @media (min-width: 1025px) {
    top: 108px;
  }
  `
  
const Tab = styled.button`
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
  background: none;
  color: #425F7E;
  white-space: nowrap;
  margin-left: 30px;
  padding-inline-start: 0;
  
  &.active {
    color: #F36940;
  }

  @media (min-width: 375px) {
    margin-left: 30px;
  }
`

const Line = styled.div`
  position: sticky;
  top: calc(88.6px + 38px + 24px);
  width: 100%;
  height: 1px;
  background: #F36940;
  margin-block-end: 20px;
  z-index: 3;

  @media (min-width: 600px) {
    top: calc(104.7px + 38px + 24px);
  }

  @media (min-width: 1025px) {
    top: calc(108.7px + 38px + 24px);
  }
`

const IconWrapper = styled.div`
  display: flex;
  margin-inline-end: 4px;

  @media (min-width: 375px) {
    margin-inline-end: 8px;
  }
`