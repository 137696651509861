import React from 'react'

export const FullHeart = ({...attrs}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="14"
      fill="none"
      viewBox="0 0 16 14"
      {...attrs}
    >
      <path
        fill="#F4AB95"
        d="M14.766 1.234a4.21 4.21 0 00-5.955 0L8 2.045l-.812-.811a4.21 4.21 0 10-5.955 5.955L2.045 8 8 13.955 13.955 8l.811-.811a4.21 4.21 0 000-5.955z"
      ></path>
    </svg>
  )
}
