import React, {useEffect} from 'react'
import styled from "styled-components";

import homePageLogo from '../images/home_page_image.png'
import imageMobile from '../images/image_mobile.png'
import {EmptyHeart} from "../icons/hearts/empty-heart.jsx";
import {HowDidIDoIt} from "./AboutMe.jsx";

export const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <HomeWrapper>
      <DesktopImage alt="two women hugging" src={homePageLogo}/>
      <Image alt="two women hugging" src={imageMobile}/>
      <Content>
        <Header>
          <div>get your</div>
          <div>eggs together</div>
        </Header>
        <MainText>
          <div> היי לכן,<ImHadas href='/about-me'> אני הדס</ImHadas>,
            <p>
              בגיל 34 גיליתי שיש לי רזרבה שחלתית נמוכה והרגשתי שהעולם קורס עליי.
                  הרופאים המליצו לי שלא להקפיא ביציות (כי לא יצא מזה כלום), והופה - מפה לשם ,23 ביציות איכותיות מוקפאות!
            </p>

          </div>
        </MainText>

        <RandomLine><div/></RandomLine>

        <SubText>
          <span>
              לא הבנתי איך אין הסברים ברשת על איך לשפר את איכות הביציות,
              לכן יצאתי למחקר של חצי שנה.
              אתן כאן כי החלטתי לספר את הסיפור של איך הצלחתי כנגד כל הסיכויים.
              כולי תקווה שהסיפור האישי שלי יעזור גם לכן <EmptyHeart />
          </span>
        </SubText>
        <HowDidIDoIt href="/eggs-factory"><span>אז איך עשיתי את זה?</span></HowDidIDoIt>
      </Content>


    </HomeWrapper>
  )
}

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #F5F5F5;
  min-height: calc(100vh - 120px - 171px);
  
  @media (min-width: 1024px) {
    flex-direction: row-reverse;
  }
`

const Image = styled.img`
  height: 382px;
  width: 100%;
  object-fit: none;

  @media (min-width: 600px) {
    object-fit: cover;
    width: 100%;
  }

  @media (min-width: 1024px) {
    display: none;
  }
`

const DesktopImage = styled.img`
  display: none;

  @media (min-width: 1024px) {
    display: initial;
    object-fit: initial;
    height: 100%;
    width: 550px;
  }
`
const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  flex-direction: column;
  background: #F5F5F5;
  padding: 34px 16px;

  @media (min-width: 600px) {
    padding: 34px 28px;
  }

  @media (min-width: 1025px) {
    padding: 0 50px;
  }

  @media (min-width: 1160px) {
    padding: 0 100px;
  }
`

const Header = styled.h1`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
  font-family: 'Eudoxus Sans';
  font-weight: 800;
  font-size: 32px;
  line-height: 44px;
  letter-spacing: 0.16em;
  white-space: nowrap;
  color: #F4AB95;
  background: #F5F5F5;
  margin-block-start: 24px;
  margin-block-end: 60px;
  
  div {
    display: flex;
    justify-content: center;
    :nth-child(2), :nth-child(3) {
      margin-inline-end: 8px;
    }
  }

  @media (min-width: 600px) {
    font-weight: 800;
    font-size: 40px;
    line-height: 60px;
    margin-block-start: 52px;
    margin-block-end: 52px;
  }
  
  @media (min-width: 1024px) {
    margin-block-start: 0px;
    margin-block-end: 50px;
    
    div {
      :last-child {
        margin-inline-end: 0;
      }
    }
  }
`

const MainText = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-block-end: 32px;
  
  div {
    display: inline-block;
    text-align: center;
    white-space: pre-line;
    max-width: 900px;
  }

  @media (min-width: 600px) {
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    margin-block-end: 64px;
  }
`

const ImHadas = styled.a`
    color: #F4AB95;
    font-weight: bold;
  text-decoration: none;

    :hover {
      color: #F36940;
      text-decoration: underline;
    }
  
`
const RandomLine = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-block-end: 32px;
  
  div {
    width: 27px;
    height: 1px;
    background: #425F7E;
  }

  @media (min-width: 600px) {
    margin-block-end: 64px;
  }
  
`

const SubText = styled.div`
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-block-end: 40px;

  @media (max-width: 520px) {
    font-size: 16px;
  }
  
  span {
    max-width: 670px;
    display: inline-block;
    text-align: center;
  }
`
