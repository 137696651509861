import React from 'react'

export const Logo = ({width, height, ...attrs}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox={`0 0 ${width} ${height}`}
      {...attrs}
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M14 23.355a9.355 9.355 0 100-18.71 9.355 9.355 0 000 18.71zm0 1.217c5.839 0 10.572-4.733 10.572-10.572C24.572 8.161 19.84 3.428 14 3.428 8.161 3.428 3.428 8.161 3.428 14c0 5.839 4.733 10.572 10.572 10.572zM12.172.118a14.127 14.127 0 013.656 0 .609.609 0 01-.158 1.207 12.91 12.91 0 00-3.34 0 .609.609 0 01-.158-1.207zm6.392 1.274a.609.609 0 01.795-.33c1.139.473 2.201 1.09 3.164 1.83a.609.609 0 11-.741.966 12.77 12.77 0 00-2.89-1.67.609.609 0 01-.328-.796zm-9.128 0a.609.609 0 01-.329.795c-1.039.43-2.009.995-2.889 1.671a.609.609 0 11-.741-.965 13.985 13.985 0 013.164-1.83.609.609 0 01.795.329zm14.818 3.973a.609.609 0 01.853.112c.74.963 1.358 2.025 1.83 3.164a.609.609 0 11-1.124.466 12.768 12.768 0 00-1.671-2.889.609.609 0 01.112-.853zm-20.508 0a.609.609 0 01.112.853c-.676.88-1.24 1.85-1.67 2.89a.609.609 0 01-1.125-.467c.472-1.139 1.09-2.201 1.83-3.164a.609.609 0 01.853-.112zM27.2 11.647a.609.609 0 01.683.525 14.12 14.12 0 010 3.656.609.609 0 01-1.208-.158 12.904 12.904 0 000-3.34.609.609 0 01.525-.683zm-26.398 0a.609.609 0 01.524.683 12.91 12.91 0 000 3.34.609.609 0 01-1.207.158 14.127 14.127 0 010-3.656.609.609 0 01.683-.525zm25.807 6.917c.31.128.458.485.33.795a13.984 13.984 0 01-1.83 3.164.609.609 0 11-.966-.741 12.77 12.77 0 001.67-2.89.609.609 0 01.796-.328zm-25.216 0a.609.609 0 01.795.329 12.77 12.77 0 001.671 2.889.609.609 0 01-.965.741 13.986 13.986 0 01-1.83-3.164.609.609 0 01.329-.795zm21.243 5.69a.609.609 0 01-.112.853 13.984 13.984 0 01-3.164 1.83.609.609 0 11-.466-1.124 12.77 12.77 0 002.889-1.671.609.609 0 01.853.112zm-17.27 0a.609.609 0 01.853-.112c.88.676 1.85 1.24 2.89 1.67a.609.609 0 11-.467 1.125 13.984 13.984 0 01-3.164-1.83.609.609 0 01-.112-.853zm6.282 2.945a.609.609 0 01.683-.525 12.904 12.904 0 003.34 0 .609.609 0 01.158 1.208 14.12 14.12 0 01-3.656 0 .609.609 0 01-.525-.683z"
        clipRule="evenodd"
      ></path>
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M15.674 9.587a1.902 1.902 0 100 3.805 1.902 1.902 0 000-3.805zm-3.12 1.902a3.12 3.12 0 116.24 0 3.12 3.12 0 01-6.24 0z"
        clipRule="evenodd"
      ></path>
    </svg>
  )
}
