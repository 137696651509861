import React from 'react'

export const NutritionLogo = ({fill, ...attrs}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="17"
      fill={fill}
      viewBox="0 0 15 17"
      {...attrs}
    >
      <path
        fill={fill}
        d="M3.625 4.875v-3.75a.625.625 0 111.25 0v3.75a.625.625 0 01-1.25 0zm11.25-3.75V15.5a.625.625 0 11-1.25 0v-3.75h-3.75a.625.625 0 01-.625-.625c.029-1.497.218-2.987.564-4.444.764-3.163 2.213-5.283 4.19-6.13a.625.625 0 01.871.574zm-1.25 1.086c-2.513 1.92-3.005 6.595-3.102 8.289h3.102V2.21zM7.366 1.023a.625.625 0 10-1.232.205l.616 3.696a2.5 2.5 0 01-5 0l.616-3.696a.625.625 0 10-1.233-.205l-.625 3.75a.638.638 0 00-.008.102 3.755 3.755 0 003.125 3.697V15.5a.625.625 0 001.25 0V8.572A3.755 3.755 0 008 4.875a.638.638 0 00-.009-.102l-.625-3.75z"
      />
    </svg>
  )
}
