import React, {useEffect} from 'react'
import styled from "styled-components";
import logo from '../images/hadas.jpg'
import {MainPageHeader} from "../App.js";

export const AboutMe = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
      <Container>
        <MainPageHeader>הסיפור האישי שלי</MainPageHeader>
        <ImgAndTextWrapper>
          <img alt="Hadas in the middle of acupuncture" src={logo} />
          <ImageDescription>בתמונה: שנת 2020, במהלך דיקור עם מחט בין העיניים להורדת מתח.</ImageDescription>
          <div>
            <TextTillImage>
              בגיל 34 הבנתי שהגיע הזמן להקפיא ביציות על מנת לשמור לעצמי את כל האופציות פתוחות בעתיד. קבעתי תור לרופא נשים שהסביר לי שיש בדיקת דם שנקראת פרופיל הורמונאלי, שעושים אותה בין היום השני לחמישי של המחזור שעוזרת להבין את מצב הרזרבה השחלתית ומומלץ להפסיק לקחת גלולות לפחות חודש לפניי הבדיקה, אז עשיתי ואחת התוצאות יצאה מחוץ לתחום - התוצאה של FSH שזה הורמון שמגרה זקיקים, שמופרש מהמוח וגורם לזקיקים שבשחלה לגדול. התוצאה מתחת ל-10 מעידה על רזרבה שחלתית טובה מאוד, בין 10 ל 15 מעידה על ירידה מתונה ברזרבה השחלתית ומעל 15 מעידה על ירידה ברזרבה השחלתית ולי יצא 17.
              הרופא אמר שאין טעם שאעשה את התהליך ואני צריכה לשקול להיכנס להריון לפני שאפספס את הרכבת.
            </TextTillImage>
            <TextTillImage>כתבתי פוסט זועם בפייסבוק, על איך זה שהרופאים לא מדברים איתנו על זה ולא שולחים אותנו לבדיקות מגיל 30, וקיבלתי הרבה תגובות, הרוב היו מופתעות שיש כזאת בדיקה ושזה משהו ששווה להיות במודעות אליו, והייתה גם תגובה שהאירה את עיניי, שכמות הזמן שאני לוקחת גלולות גם יכולה להשפיע על התוצאה ושאולי אנקה את הגוף מהגלולות (יותר מחודש) ואעשה שוב את הבדיקה . </TextTillImage>
            <TextTillImage>אז ניסיתי ואחרי חודש יצא יותר גרוע FSH 21.
            הלכתי ל-5 רופאים שונים כדי לקבל חוות דעת מה אפשר לעשות וכולם היו שליליים.
            גיליתי שיש לי האשימוטו, שזאת מחלה אוטואימונית, שהגוף תוקף את עצמו.
            עשיתי בדיקת AMH שאמורה לדייק את מצב הפוריות ויצא 0.14, כשמעל 1 נחשב תקין ומתחת ל0.3 נחשב לכמות ביציות מאוד נמוכה.
            החלטתי לקחת את העניינים לידיים ואמרתי, שאם אני עושה את ההקפאה הזאת, אני עושה את המקסימום שאצליח!
            עשיתי מחקר חוצה אינטרנט ומעבר לו והבנתי, שיש הרבה דברים שאני יכולה לעשות בשביל לשפר את הביולוגיה של עצמי ויצאתי למסע של חצי שנה, שבו הפכתי את הגוף שלי למפעל ביציות.
            </TextTillImage>
            <p>
            אחרי חצי שנה קבעתי תור לאיכילוב, שילמתי 11,000 ש"ח לשני סבבים.
              קיבלתי את הזריקות הבאות: מונופור 300 מ"ג ביום, אורגלוטרן ודקפפטיל. הרופאים היו בהלם מכמות הזקיקים…. מפה לשם, סבב ראשון מתוך 17 זקיקים שהיו בשלים, חלק הבשילו יותר מדי, אז הצליחו לאסוף 12 ביציות, מתוכן 11 איכותיות ובהקפאה. בסבב השני הקפיאו 12 מתוך 17, אז סה״כ 23 ביציות איכותיות.
            </p>

            <HowDidIDoIt href="/eggs-factory"><span>אז איך עשיתי את זה?</span></HowDidIDoIt>

          </div>
        </ImgAndTextWrapper>
      </Container>

  )
}

const Container = styled.div`
  position: relative;
  min-height: calc(100vh - 120px - 171px);
  background: #F5F5F5;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  padding: 34px 16px;

  @media (min-width: 600px) {
    padding: 36px 28px;
  }
  
  @media (min-width: 1025px) {
    padding: 52px 100px;
    font-size: 20px;
    line-height: 28px;
  }
`

const ImgAndTextWrapper = styled.div`
  display: flex;
  
  @media (max-width: 1100px) {
    flex-direction: column;
  }
  
  img {
    max-width: 250px;
    max-height: 250px;
    position: absolute;
    left: 100px;
    top: 100px;

    @media (max-width: 1100px) {
      position: inherit;
    }
  }
`

const ImageDescription = styled.span`
  font-size: 12px;
  margin-block-start: 5px;
  margin-block-end: 24px;
  line-height: 20px;
  position: absolute;
  left: 100px;
  top: 355px;
  max-width: 250px;
  max-height: 250px;

  @media (max-width: 1100px) {
    position: inherit;
  }
  
`

const TextTillImage = styled.p`
  max-width: calc(100% - 250px - 64px);
  @media (max-width: 1100px) {
    max-width: 100%;
  }
`

export const HowDidIDoIt = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bolder;
  font-size: 20px;
  line-height: 32px;
  color: #F4AB95;

  :hover {
    color: #F36940;
    text-decoration: underline;
  }

  span {
    margin-inline-end: 8px;
  }
`