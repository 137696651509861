import React from 'react'

export const EmptyHeart = ({fill, ...attrs}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      fill="none"
      viewBox="0 0 12 11"
      {...attrs}
    >
      <path
        stroke="#425F7E"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.646 1.806a2.749 2.749 0 00-3.89 0l-.53.53-.53-.53a2.75 2.75 0 00-3.89 3.89l4.42 4.42 4.42-4.42a2.75 2.75 0 000-3.89z"
      ></path>
    </svg>
  )
}
