import React from 'react'

export const LifeStyleLogo = ({fill, ...attrs}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="14"
      fill="none"
      viewBox="0 0 22 14"
      {...attrs}
    >
      <path
        fill={fill}
        d="M17.875 5.625c-.497 0-.99.09-1.455.266l-2.16-3.704h2.24a.688.688 0 01.687.688.688.688 0 001.375 0A2.063 2.063 0 0016.5.812h-3.438a.688.688 0 00-.593 1.032l1 1.718H8.53L7.125 1.156A.687.687 0 006.53.813H4.125a.688.688 0 100 1.375h2.012l1.178 2.02-1.35 1.852a4.136 4.136 0 101.11.811l.996-1.366 2.679 4.589a.688.688 0 101.187-.688L9.332 4.937h4.94l.961 1.648a4.125 4.125 0 102.642-.96zm-11 4.125a2.75 2.75 0 11-1.737-2.556L3.57 9.343a.687.687 0 101.11.81l1.568-2.15a2.74 2.74 0 01.627 1.747zm11 2.75a2.75 2.75 0 01-1.935-4.702l1.341 2.296a.69.69 0 00.96.288.687.687 0 00.228-.976l-1.342-2.302a2.75 2.75 0 11.748 5.396z"
      ></path>
    </svg>
  )
}
