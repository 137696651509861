import React from "react";
import Nav from 'react-bootstrap/Nav';
import styled from 'styled-components';
import { Route,  Routes, NavLink} from "react-router-dom";

import { Home } from "./components/Home.jsx";
import { EggsFactory } from "./components/EggsFactory.jsx";
import {AboutMe} from "./components/AboutMe.jsx";
import {Logo} from "./icons/logo.jsx";
import {FullHeart} from "./icons/hearts/full-heart.jsx";

function App() {
  return (
    <div>
      <StyledNav>
        <div>
          <NavLink to="/about-me" className="nav-item" style={({ isActive }) => ({
            color: isActive ? '#F36940' : '#FFFBF5', fontWeight: isActive ? '700px' : '500px', cursor: isActive ? 'default' : 'pointer' })}>סיפור אישי</NavLink>
          <NavLink to="/eggs-factory" className="nav-item" style={({ isActive }) => ({
            color: isActive ? '#F36940' : '#FFFBF5', fontWeight: isActive ? '700px' : '500px', cursor: isActive ? 'default' : 'pointer' })}>מפעל ביציות</NavLink>
        </div>
        <StyledSmallLogoLink to="/"><Logo alt="Logo" width="28px" height="28px"/></StyledSmallLogoLink>
        <StyledLargeLogoLink to="/"><Logo alt="Logo" width="44px" height="44px"/></StyledLargeLogoLink>

      </StyledNav>
      <Routes>
         <Route path="/" element={<Home/>} />
         <Route path="/eggs-factory" element={<EggsFactory/>} />
         <Route path="/about-me" element={<AboutMe/>} />
         <Route path="*" element={<Home/>} />
      </Routes>
      <Footer>
        <MadeWithLove>האתר נעשה ב - <FullHeart/> ע״י שחר והדס</MadeWithLove>
        <ContactWrapper>
          <ContactHeader>לסיפורים מעוררי השראה או לשאלות, מוזמנות ליצור קשר במייל הבא:</ContactHeader>
          <ContactContent>getyoureggstogether@gmail.com</ContactContent>
        </ContactWrapper>
        <RightsReserved>
          &#169; 2023 Get Your Eggs Together
        </RightsReserved>
      </Footer>
    </div>

  );
}

export const MainPageHeader = styled.h2`
  font-weight: 700;
  font-size: 32px;
  line-height: 47px;
  margin-block-end: 16px;

  @media (min-width: 768px) {
    font-size: 40px;
  }
`

const StyledNav = styled(Nav)`
  display: flex;
  padding: 15px 100px;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: sticky; 
  top: 0;
  z-index: 1;
  background: #F4AB95;
  
  .nav-item {
    all: unset;
    text-decoration: none;
    font-size: 14px;
    margin-inline-end: 29px;
    line-height: 20.5px;

    :last-child {
      margin: 0
    }

    @media (min-width: 600px) {
      font-size: 20px;
      line-height: 29.4px;
    }
  }

  padding: 28px 16px;
  
  @media (min-width: 600px) {
    padding: 30px 28px;
  }

  @media (min-width: 1025px) {
    padding: 30px 100px;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  line-height: 24px;
  background: #FFFBF5;
  padding: 24px 16px;

  @media (min-width: 600px) {
    padding: 24px 28px;
  }

  @media (min-width: 1025px) {
    flex-direction: row;
    padding: 52px 100px;
  }
`

const MadeWithLove = styled.div`
  color: #F4AB95;
  margin-inline-end: 12px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-block-end: 12px;

  @media (min-width: 1025px) {
    width: 220px;
  }
`

const ContactWrapper = styled.div`
  width: 100%;
  margin-block-end: 20px;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
`

const ContactHeader = styled.p`
  font-size: 12px;
  margin: 0;
`

const ContactContent= styled.p`
  font-size: 12px;
  width: 443px;
  margin: 0;

  @media (max-width: 500px) {
    width: 100%;
  }
`

const StyledSmallLogoLink = styled(NavLink)`
  margin-inline-start: 8px;
  display: flex;

  @media (min-width: 600px) {
    display: none;
  }
`

const StyledLargeLogoLink = styled(NavLink)`
  margin-inline-start: 8px;
  display: none;

  @media (min-width: 600px) {
    display: flex;
  }
`

const RightsReserved= styled.div`
  font-weight: 400;
  font-size: 10px;
  line-height: 24px;

  @media (min-width: 1025px) {
    width: 220px;
  }
`

export default App;
