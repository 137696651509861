import React from 'react'

export const SupplementLogo = ({fill, ...attrs}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      fill="none"
      viewBox="0 0 14 14"
      {...attrs}
    >
      <path
        fill={fill}
        d="M12.526 1.475a3.329 3.329 0 00-4.707 0L1.475 7.818a3.329 3.329 0 004.708 4.707l6.344-6.343a3.332 3.332 0 000-4.707zm-7.05 10.343a2.329 2.329 0 01-3.294-3.293L5 5.707 8.294 9l-2.818 2.818zm6.343-6.343L9 8.293 5.708 5l2.818-2.818a2.329 2.329 0 013.293 3.293zm-.964-1.329a.5.5 0 010 .708l-1.5 1.5a.5.5 0 11-.707-.708l1.5-1.5a.5.5 0 01.707 0z"
      ></path>
    </svg>
  )
}
